/* purgecss start ignore */

.style-module__wrapper___3MLpd {
  width: 100%;
}

.style-module__sidebar___Yx3Kl {
  grid-column: 1;
  padding-right: 4px;
}

.style-module__sidebar___Yx3Kl a {
  color: var(--text-dark-color);
}

/* purgecss end ignore */
