/* purgecss start ignore */

.markdown-module__markdown___2qEx2 h2 {
  /* Tailwind text-xl */
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: 1.5rem;
}

.markdown-module__markdown___2qEx2 h3 {
  /* Tailwind text-lg */
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-top: 1.5rem;
}

.markdown-module__markdown___2qEx2 h4 {
  /* Tailwind text-base */
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.25rem;
}

/* purgecss end ignore */
