/* purgecss start ignore */

.style-module__wrapper___2MTLC {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

@media (max-width: 800px) {
  .style-module__ascii___3npq0 {
    font-size: 5px;
    font-weight: bolder;
  }
}

/* purgecss end ignore */
