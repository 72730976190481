/* purgecss start ignore */

.style-module__wrapper___23Eax {
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 32px;
  padding: 32px;
}

@media (min-width: 484px) {
  .style-module__wrapper___23Eax {
    padding: 48px;
    row-gap: 64px;
  }
}

.style-module__emailInput___3hTGo {
  margin-top: 16px;
}

.style-module__passwordInput___2r15e {
  margin-top: 16px;
}

.style-module__content___1vv7Z {
  width: 100%;
}

.style-module__image___2vE6g {
  width: 100%;
}

.style-module__cardActions___17V-e {
  display: flex;
  justify-content: flex-end;
}

/* purgecss end ignore */