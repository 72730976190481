/* purgecss start ignore */

@media (min-width: 600px) {
  .style-module__iconButton___3m9sD {
    display: none;
  }

  .style-module__consoleButton___2u_CM {
    display: block;
  }
}

@media (max-width: 599px) {
  .style-module__iconButton___3m9sD {
    display: block;
  }

  .style-module__consoleButton___2u_CM {
    display: none;
  }

  .style-module__signupButton___1ONy8 {
    display: none;
  }
}

.style-module__authButtons___2wsOR {
  display: flex;
  justify-content: space-between;
}

/* purgecss end ignore */