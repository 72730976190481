/* purgecss start ignore */

.style-module__setting___3eDJu {
  width: 100%;
  margin-bottom: 8px;
}

.style-module__heading___1Ly7B {
  font-size: 24px;
  margin-bottom: 16px;
}

/* purgecss end ignore */
