/* purgecss start ignore */

.style-module__contentBlock___1EHXY {
  width: 100%;
  padding: 40px 0;
  color: var(--text-light-color);
}

.style-module__contentBlock___1EHXY > div > div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 865px) {
  .style-module__contentBlock___1EHXY > div > div {
    flex-direction: column;
    align-items: center;
  }
}

.style-module__contentBlock___1EHXY:nth-child(odd) {
  background-color: var(--primary-color-light);
}

.style-module__contentBlock___1EHXY:nth-child(even) {
  background-color: #ba3a99;
}

@media (min-width: 865px) {
  .style-module__contentBlock___1EHXY:nth-child(even) > div > div {
    flex-direction: row-reverse;
  }

  .style-module__contentBlock___1EHXY > div > div > img {
    width: initial;
  }
}

.style-module__contentBlock___1EHXY > div > div > div {
  padding: 64px;
}

@media (max-width: 865px) {
  .style-module__contentBlock___1EHXY > div > div > div {
    max-width: 100%;
    padding: 16px;
  }
}

.style-module__contentBlock___1EHXY > div > div > img {
  width: 50%;
  margin-top: -70px;
}

.style-module__contentBlock___1EHXY:nth-child(1) > div > div > img {
  margin-top: 0;
}

/* purgecss end ignore */