/* purgecss start ignore */

html {
  --text-dark-color: #4a5960;
  --text-light-color: #f5f5f5;
  --primary-color-main: #1e88e5;
  --primary-color-dark: #2196f3;
  --primary-color-light: #42a5f5;
  --primary-color-disabled: #90caf9;
  --primary-color-text: var(--text-light-color);
  --primary-color: var(--primary-color-main);
  --dark-primary-color: var(--primary-color-dark);
  --light-primary-color: var(--primary-color-light);
  --secondary-color-main: #1e88e5;
  --secondary-color-dark: #2196f3;
  --secondary-color-light: #42a5f5;
  --secondary-color-text: var(--text-light-color);
  --divider-color: #bdbdbd;
  --primary-text-color: var(--text-dark-color);
  --background-dark-color: #37474f;
  --background-light-color: #fafafa;
  --background-color: #f5f5f5;
  --status-color-okay: #4caf50;
  --status-color-error: #f44336;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Clear Sans', 'Noto', sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  color: var(--text-dark-color);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

h1 {
  font-size: 22px;
  font-weight: 600;
}

h2 {
  font-size: 20px;
  font-weight: 600;
}

a {
  color: var(--primary-color-main);
  text-decoration: none;
}

/* purgecss end ignore */
