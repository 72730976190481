/* purgecss start ignore */

.style-module__wrapper___2hhbu {
  margin: 0 16px;
}

@media (max-width: 959px) and (min-width: 600px) {
  .style-module__wrapper___2hhbu {
    margin: 0 48px;
  }
}

@media (min-width: 960px) {
  .style-module__wrapper___2hhbu {
    margin: 0 80px;
  }
}

.style-module__autoComplete___cPcgZ {
  color: white;
}

/* purgecss end ignore */