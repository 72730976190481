/* purgecss start ignore */

.style-module__wrapper___2QM67 {
  display: grid;
  box-sizing: border-box;
  padding: 16px;
  grid-template-columns: -webkit-min-content auto;
  grid-template-columns: min-content auto;
}

.style-module__drawer___3glCp {
  width: 0;
  box-sizing: border-box;
  transition-property: width opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  opacity: 0;
}

.style-module__drawerOpened___1tlWR {
  width: 200px;
  opacity: 1;
  margin-right: 16px;
}

/* purgecss end ignore */
