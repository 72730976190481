/* purgecss start ignore */

.style-module__masthead___2X0h2 {
  background: #f8f9fa;
}

.style-module__masthead__container___3kAPD {
  box-sizing: content-box;
  margin: 0 auto;
  max-width: 960px;
  overflow: visible;
  padding: 24px 24px 24px;
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
}

@media (min-width: 481px) {
  .style-module__masthead__container___3kAPD {
    padding: 72px 24px;
  }
}

@media (min-width: 865px) {
  .style-module__masthead__container___3kAPD {
    flex-direction: row;
    justify-content: space-around;
  }
}

.style-module__masthead__left___RlZJw {
  width: 100%;
}

@media (min-width: 865px) {
  .style-module__masthead__left___RlZJw {
    max-width: 408px;
  }
}

.style-module__masthead__right___2cHkx {
  width: 100%;
}

@media (min-width: 865px) {
  .style-module__masthead__right___2cHkx {
    width: 400px;
  }
}

.style-module__masthead__right___2cHkx > img {
  width: 100%;
}

.style-module__masthead__buttons___1xuff {
  display: flex;
  justify-content: center;
}

.style-module__pages___1xv5J {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  row-gap: 16px;
  -moz-column-gap: 16px;
       column-gap: 16px;
  padding: 16px;
}

@media (min-width: 481px) {
  .style-module__pages___1xv5J {
    padding: 24px;
    -moz-column-gap: 24px;
         column-gap: 24px;
    padding: 24px;
  }
}

@media (min-width: 865px) {
  .style-module__pages___1xv5J {
    padding: 64px;
    row-gap: 32px;
    -moz-column-gap: 32px;
         column-gap: 32px;
  }
}

/* purgecss end ignore */