/* purgecss start ignore */

.style-module__wrapper___3CW6K {
  display: grid;
  box-sizing: border-box;
  padding: 16px;
  grid-template-columns: -webkit-min-content auto;
  grid-template-columns: min-content auto;
}

.style-module__drawer___3qPjN {
  width: 0;
  box-sizing: border-box;
  transition-property: width opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  opacity: 0;
}

.style-module__drawerOpened___1yYAU {
  width: 200px;
  opacity: 1;
  margin-right: 16px;
}

.style-module__treeItem___1hhDY {
  text-transform: capitalize;
}

/* purgecss end ignore */
