/* purgecss start ignore */

.style-module__wrapper___geuky {
  display: grid;
  height: 100vh;
  grid-template-rows: -webkit-min-content 1fr;
  grid-template-rows: min-content 1fr;
}

.style-module__body___pCB1G {
  overflow-y: auto;
}

.style-module__content___3q9oG {
  min-height: 100%;
}

/* purgecss end ignore */
