/* purgecss start ignore */

.style-module__card___laPdO {
  width: 100%;
}

.style-module__inputs___3W9jY {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.style-module__input___1pjmp {
  width: 100%;
  margin: 4px;
}

@media only screen and (max-width: 800px) {
  .style-module__inputs___3W9jY {
    flex-direction: column;
  }

  .style-module__input___1pjmp {
    margin-top: 8px;
  }
}

/* purgecss end ignore */
