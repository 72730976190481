/* purgecss start ignore */

.style-module__card___1ta7_ {
  width: 100%;
}

.style-module__passwordInputs___30Cu1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.style-module__input___mtNJ5 {
  width: 100%;
  margin: 4px;
}

@media only screen and (max-width: 800px) {
  .style-module__passwordInputs___30Cu1 {
    flex-direction: column;
  }

  .style-module__input___mtNJ5 {
    margin-top: 8px;
  }
}

/* purgecss end ignore */
