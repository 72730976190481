/* purgecss start ignore */

.style-module__element___Kzse3 {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 24px;
  z-index: 5;
}

.style-module__card___1tiGh {
  max-width: 350px;
}

@media (max-width: 481px) {
  .style-module__card___1tiGh {
    max-width: initial;
    left: 0;
  }
}

/* purgecss end ignore */
