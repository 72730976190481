/* purgecss start ignore */

.style-module__names___aqiV3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -moz-column-gap: 6px;
       column-gap: 6px;
}

.style-module__input___1cvqs {
  margin-top: 10px;
}

.style-module__cardActions___1CZuf {
  display: flex;
  justify-content: flex-end;
}

/* purgecss end ignore */
