/* purgecss start ignore */

.style-module__navButton___jUG4a {
  /* text-decoration: none; */
  font-size: 0.8em;
  text-transform: capitalize;
  margin: 0;
}

@media only screen and (min-width: 750px) {
  .style-module__popButton___aNqY9 {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .style-module__popButton___aNqY9 {
    display: block;
    display: flex;
    justify-content: center;
  }

  .style-module__fullNavigation___3gjmb {
    display: none;
  }
}

/* purgecss end ignore */
