/* purgecss start ignore */

.style-module__wrapper___1X_4D {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

@media only screen and (min-width: 960px) {
  .style-module__container___3YugF {
    width: 60%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
  .style-module__container___3YugF {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .style-module__container___3YugF {
    width: 100%;
  }
}

.style-module__setting___3lgsA {
  width: 100%;
  margin-bottom: 8px;
}

.style-module__heading___hTqNW {
  font-size: 24px;
  margin-bottom: 16px;
}

/* purgecss end ignore */
