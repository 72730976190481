/* purgecss start ignore */

.style-module__background___10iuM {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: -1;
}

/* purgecss end ignore */
