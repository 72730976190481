/* purgecss start ignore */

.style-module__wrapper___-eOxn {
  position: relative;
  width: 100%;
  height: 100%;
}

.style-module__gradient___1ae8r {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    var(--primary-color-light) 0%,
    #3949ab 90%
  );
}

.style-module__antigradient___1JQl_ {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(20deg, #e91e63 -40%, rgba(0, 0, 0, 0) 70%);
}

/* @media only screen and (min-width: 960px) {
  .gradient,
  .antigradient {
    clip-path: polygon(0 0, 0 85%, 100% 40%, 100% 0);
  }
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
  .gradient,
  .antigradient {
    clip-path: polygon(0 0, 0 55%, 100% 40%, 100% 0);
  }
}

@media only screen and (max-width: 600px) {
  .gradient,
  .antigradient {
    clip-path: polygon(0 0, 0 72%, 100% 58%, 100% 0);
  }
} */

/* purgecss end ignore */
