/* purgecss start ignore */

.style-module__content___2kJG0 {
  color: var(--text-light-color);
  box-sizing: border-box;
}

.style-module__top___3mUNm {
  background-color: var(--text-dark-color);
}

.style-module__topWrapper___dSnxx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 660px) {
  .style-module__topWrapper___dSnxx {
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.style-module__bottom___3G7mD {
  background-color: var(--background-dark-color);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* purgecss end ignore */
