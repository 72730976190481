/* purgecss start ignore */

.style-module__card___dom92 {
  width: 100%;
}

.style-module__cardHeaderWrapper___212gA {
  display: grid;
  grid-template-columns: -webkit-min-content 1fr -webkit-min-content;
  grid-template-columns: min-content 1fr min-content;
  grid-column-gap: 4px;
  align-items: center;
  white-space: nowrap;
}

.style-module__cardDetails___2B8Zy {
  border-top: 1px solid var(--text-dark-color);
  padding: 24px;
}

.style-module__invoiceItem___H6hFS {
  width: 100%;
}

.style-module__table___1Q335 {
  width: 100%;
  display: table;
}

.style-module__tableHeader___3hlaz {
  display: table-row;
  border-bottom: 1px solid var(--text-dark-color);
}

.style-module__tableRow___gsi36 {
  display: table-row;
}

.style-module__tableCell___1feXG {
  display: table-cell;
}

.style-module__totalCost___eHeZc {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

/* purgecss end ignore */
